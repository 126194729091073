import React from "react";
import clientLogo from "../images/client-logo2.png";
const Sponsor = () => {
  return (
    <section className="sponsor-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="sponsor-wrap">
              <div className="row">
                <div className="col-lg-3">
                  <div className="logo-item logo-item1">
                    <img src={clientLogo} alt="" />
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="sponsor-item">
                    <h3 className="sponsor__title">Company name here 01</h3>
                    <span className="sponsor__meta">brisbane, australia</span>
                    <p className="sponsor__text">
                      Phasellus vehicul justo eget diam dosuere sollicitudin eu
                      tincidun. Lorem Ipsum is simply dummy text of the printing
                      and typesetting industry been the industry's standard
                      dummy text ever since.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="sponsor-wrap">
              <div className="row">
                <div className="col-lg-3">
                  <div className="logo-item logo-item2">
                    <img src={clientLogo} alt="" />
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="sponsor-item">
                    <h3 className="sponsor__title">Company name here 01</h3>
                    <span className="sponsor__meta">brisbane, australia</span>
                    <p className="sponsor__text">
                      Phasellus vehicul justo eget diam dosuere sollicitudin eu
                      tincidun. Lorem Ipsum is simply dummy text of the printing
                      and typesetting industry been the industry's standard
                      dummy text ever since.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="sponsor-wrap">
              <div className="row">
                <div className="col-lg-3">
                  <div className="logo-item logo-item3">
                    <img src={clientLogo} alt="" />
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="sponsor-item">
                    <h3 className="sponsor__title">Company name here 01</h3>
                    <span className="sponsor__meta">brisbane, australia</span>
                    <p className="sponsor__text">
                      Phasellus vehicul justo eget diam dosuere sollicitudin eu
                      tincidun. Lorem Ipsum is simply dummy text of the printing
                      and typesetting industry been the industry's standard
                      dummy text ever since.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="sponsor-wrap">
              <div className="row">
                <div className="col-lg-3">
                  <div className="logo-item logo-item4">
                    <img src={clientLogo} alt="" />
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="sponsor-item">
                    <h3 className="sponsor__title">Company name here 01</h3>
                    <span className="sponsor__meta">brisbane, australia</span>
                    <p className="sponsor__text">
                      Phasellus vehicul justo eget diam dosuere sollicitudin eu
                      tincidun. Lorem Ipsum is simply dummy text of the printing
                      and typesetting industry been the industry's standard
                      dummy text ever since.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="sponsor-wrap">
              <div className="row">
                <div className="col-lg-3">
                  <div className="logo-item logo-item5">
                    <img src={clientLogo} alt="" />
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="sponsor-item">
                    <h3 className="sponsor__title">Company name here 01</h3>
                    <span className="sponsor__meta">brisbane, australia</span>
                    <p className="sponsor__text">
                      Phasellus vehicul justo eget diam dosuere sollicitudin eu
                      tincidun. Lorem Ipsum is simply dummy text of the printing
                      and typesetting industry been the industry's standard
                      dummy text ever since.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sponsor;
